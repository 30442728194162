import React from 'react';
import { ButtonStandard } from '@src/components/Button';
import { CreateLeadForm } from '@src/components/Forms';
import { GlobalSettingItemFragment, Maybe } from '@src/graphql/gql-types';
import { COLOR_LIME } from '@src/types/colors';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { useRouter } from 'next/router';
import useModalStore from '@src/store';
import { ButtonSize } from '@src/types/buttons';

interface EnquireButton {
  isCommunity: boolean;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment;
  headOfficePhoneNumber?: Maybe<string>;
  handleCloseMobileMenu?: (event: React.MouseEvent) => void;
  testid: string;
  size?: ButtonSize;
}

const EnquireButton: React.FC<EnquireButton> = ({
  isCommunity,
  dynamicFormValues,
  formDisclaimerRichText,
  headOfficePhoneNumber,
  handleCloseMobileMenu,
  testid,
  size = 'md'
}) => {
  const { openModal } = useModalStore();
  const router = useRouter();

  const handleEnquireButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (handleCloseMobileMenu) {
      handleCloseMobileMenu(event);
    }
    if (router.asPath.includes('contact-us') && isCommunity) {
      requestAnimationFrame(() => {
        // ensure hash param is cleared so the form re-renders
        if (router.asPath.includes('#')) {
          const currentRouteWithoutHashParam = router.asPath.split('#')[0];
          router.push(currentRouteWithoutHashParam);
        }
        // target DOM node to scroll to (contact form)
        document
          .getElementById('contactScrollAnchor')
          ?.scrollIntoView({ behavior: 'smooth' });
      });
    } else {
      const modalData = {
        heading: 'Enquire',
        content: (
          <CreateLeadForm
            isModal
            disclaimerRichText={formDisclaimerRichText}
            formType={isCommunity ? 'community_enquiry' : 'national_enquiry'}
            headOfficePhoneNumber={headOfficePhoneNumber}
            nationalEnquiryDynamicFormValues={
              isCommunity
                ? {}
                : (dynamicFormValues as NationalEnquiryDynamicFormValues)
            }
          />
        )
      };

      openModal(modalData);
    }
  };

  return (
    <ButtonStandard
      data-testid={testid}
      color={COLOR_LIME}
      colorVariant={'dark'}
      aria-label="Enquire"
      size={size}
      onClick={(event) => handleEnquireButtonClick(event)}
    >
      Enquire
    </ButtonStandard>
  );
};

export default EnquireButton;
