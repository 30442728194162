import {
  FORM_ERROR_URL_PARAMETER,
  FORM_SUCCESS_URL_PARAMETER
} from '@src/types/forms';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

declare const grecaptcha: any;

export const useRecaptcha = (id: string, isModal = false) => {
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const submitHashes: string[] = [
    FORM_SUCCESS_URL_PARAMETER.CONTACT,
    FORM_SUCCESS_URL_PARAMETER.RSVP,
    FORM_ERROR_URL_PARAMETER.RSVP
  ];

  const router = useRouter();
  const slug = router.asPath.match(/#([a-z0-9-]+)/gi)?.[0]?.split('#')[1] ?? '';

  const verifyCallback = async (response: any) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ reCaptchaResponse: response, action: 'FORM' })
    };
    const res = await fetch('/api/validateReCaptcha', options);
    const resJson = await res.json();
    const score = await resJson?.score;
    if (score >= 0.5) {
      return setRecaptchaVerified(true);
    } else {
      return setRecaptchaVerified(false);
    }
  };

  const expiredCallback = () => {
    setRecaptchaVerified(false);
  };

  useEffect(() => {
    // grecaptcha doesn't have a destroy function so we try to render first
    // checking it doesn't try to render a ReCaptcha when the div doesn't exist (submit success page)
    // but still renders if the enquire button is clicked while on the success page
    try {
      // /#contact-success & /#rsvp-success paths don't redirect and instead a success message has been rendered in place of the form fields
      // and therefore there will be no widget to render the recaptcha
      if (
        !submitHashes.includes(slug) ||
        (submitHashes.includes(slug) && isModal)
      ) {
        grecaptcha.enterprise.ready(() => {
          setRecaptchaVerified(false);
          //check if recaptcha widget container has been loaded before attempting injection
          if (!document.getElementById(id)?.hasChildNodes()) {
            grecaptcha.enterprise.render(id, {
              sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
              callback: verifyCallback,
              'expired-callback': expiredCallback,
              action: 'FORM',
              theme: 'light'
            });
          }
        });
      }
    } catch {
      try {
        // attempt to reset ReCaptcha widget if already on page (duplicate widget error)
        // this will only happen on dev with hot reload
        grecaptcha.enterprise.reset(id);
        // eslint-disable-next-line no-empty
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return recaptchaVerified;
};
