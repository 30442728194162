import {
  CheckboxInput,
  FieldSet,
  RadioSelectionInput,
  TextAreaInput,
  TextInput
} from '@src/elements/Form';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecaptcha } from '@src/hooks/useRecaptcha';
import { usePageFormSuccess } from '@src/hooks/usePageFormSuccess';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CREATE_CASE_FORM_SCHEMA,
  FORM_SUBMIT_DELAY_MS
} from '@src/constants/forms';
import {
  CreateCaseFormFields,
  FORM_SUCCESS_URL_PARAMETER,
  NationalEnquiryDynamicFormValues
} from '@src/types/forms';
import { ButtonStandard } from '@src/components/Button';
import { COLOR_LIME } from '@src/types/colors';
import { useWindow } from '@src/hooks/useWindow';
import logger from '@src/utils/logger';
import UrlRedirectInput from '../UrlRedirectInput';
import { GlobalSettingItemFragment } from '@src/graphql/gql-types';
import useFormInit from '@src/hooks/useFormInit';
import { promiseDelayLeading } from '@src/utils/promise';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import styles from './CreateCaseForm.module.scss';
import {
  SVG_FEEDBACK,
  SVG_HELP_SOLID,
  SVG_QUESTION_ANSWER
} from '@src/types/svg';

type CreateCaseForm = {
  disclaimerRichText?: GlobalSettingItemFragment;
  nationalEnquiryDynamicFormValues?: NationalEnquiryDynamicFormValues;
};

const CreateCaseForm: React.FC<CreateCaseForm> = ({
  disclaimerRichText,
  nationalEnquiryDynamicFormValues = {}
}) => {
  const windowObj = useWindow();
  const formRef = useRef<HTMLFormElement | null>(null);
  const recaptchaRef = useRef<HTMLDivElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaVerified = useRecaptcha('recaptcha-widget');
  const formSuccess = usePageFormSuccess();

  // dynamic state/territory radio values
  const stateTerritoryValues = Object.keys(nationalEnquiryDynamicFormValues);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<CreateCaseFormFields>({
    resolver: yupResolver(CREATE_CASE_FORM_SCHEMA),
    defaultValues: {
      email: '',
      phone: '',
      location: '',
      name: '',
      subject: 'Customer Enquiry',
      description: '', // details
      state: '',
      optIn: 0,
      recordType: '' // recordType
    }
  });

  // initialise form - set form action and method, oid / orgid field (prevent no-javascript submission)
  useFormInit('web-to-case', formRef, setValue);

  // submit form with synthetic delay after validating reCaptcha
  const formSubmit = async (data: CreateCaseFormFields) => {
    console.log('submit form');
    setIsSubmitting(true);
    try {
      if (!recaptchaVerified) {
        setIsSubmitting(false);
        return false;
      }

      switch (process.env.NEXT_PUBLIC_ENV) {
        case 'PROD':
          setValue('00NMq0000000dgR', data['location']);
          setValue('00NMq0000000dgU', data['state']);
          setValue('00NMq0000000dgS', data['optIn']);
          setValue('recordType', '012Mq0000003pcJIAQ');
          setValue('orgid', '00D5g00000FmRme');
          break;
        case 'SIT':
          setValue('00N6D00000FaPdD', data['location']);
          setValue('00N6D00000FaPdG', data['state']);
          setValue('00N6D00000FaPdE', data['optIn']);
          setValue('recordType', '0126D000002pidi');
          setValue('orgid', '00D6D0000008j7C');
          break;
        case 'UAT':
          setValue('00N9n000000QNTd', data['location']);
          setValue('00N9n000000QNTg', data['state']);
          setValue('00N9n000000QNTe', data['optIn']);
          setValue('recordType', '0129n000000UIh5AAG');
          setValue('orgid', '00D92000002lKer');
          break;
        default: // LOCAL and DEV
          setValue('00N1y00000CFsOI', data['location']);
          setValue('00N1y00000CFsDK', data['state']);
          setValue('00N1y00000CFsOD', data['optIn']);
          setValue('recordType', '0121y000002JzmA');
          setValue('orgid', '00D1y0000008qTD');
          break;
      }

      setValue('subject', `Customer Enquiry ${data['name']}`);

      // log submit to datadog
      logger.info('forms v2 submit National Lead (web-to-case)', {
        form: 'National Lead (web-to-case)',
        source: 'Web',
        service: 'Form'
      });
      const body = {
        ...getValues(),
        enquiryDate: new Intl.DateTimeFormat('en-AU').format(
          new Date(Date.now())
        ),
        channelOfEnquiry: 'Website Form',
        leadSource: 'Website',
        leadRecordType: 'Website',
        websiteURL: windowObj.href,
        sourceDetail: windowObj.origin
      };

      const FD = new FormData();

      // Push our data into our FormData object
      for (const [name, value] of Object.entries(body)) {
        FD.append(name, value as string);
      }

      // force leading time delay for execute
      await promiseDelayLeading(
        FORM_SUBMIT_DELAY_MS,
        () => formRef.current?.submit()
      ).then(() => {
        try {
          // this checks that the recaptcha exists on the page after submit ie. contact-success page
          if (recaptchaRef.current) {
            grecaptcha.enterprise.reset();
          }
          // eslint-disable-next-line no-empty
        } catch {}
      });
    } catch (err) {
      setIsSubmitting(false);
      // log exception to Sentry
      try {
        grecaptcha.enterprise.reset();
        // eslint-disable-next-line no-empty
      } catch {}
      throw err;
    }
  };

  return (
    <>
      {formSuccess ? (
        <p data-testid="communitySuccessHeader">
          Thank you for submitting an enquiry, we will be in touch for a chat
          shortly.
        </p>
      ) : (
        <form
          // form action and method are set by useFormInit hook
          action={process.env.NEXT_PUBLIC_BASE_URL}
          method="GET"
          data-testid="nationalContactForm"
          noValidate
          onSubmit={handleSubmit(formSubmit)}
          ref={formRef}
          className={styles.webToCaseForm}
        >
          {/* Currently always successful as fire and forget */}
          <UrlRedirectInput urlParam={FORM_SUCCESS_URL_PARAMETER.CONTACT} />
          <input type="hidden" {...register('orgid')} />
          <input type="hidden" {...register('recordType')} />
          <input type="hidden" {...register('subject')} />

          {(process.env.NEXT_PUBLIC_ENV === 'DEV' ||
            process.env.NEXT_PUBLIC_ENV === 'LOCAL') && (
            <>
              <input type="hidden" {...register('00N1y00000CFsOI')} />
              <input type="hidden" {...register('00N1y00000CFsDK')} />
              <input type="hidden" {...register('00N1y00000CFsOD')} />
            </>
          )}

          {process.env.NEXT_PUBLIC_ENV === 'SIT' && (
            <>
              <input type="hidden" {...register('00N6D00000FaPdD')} />
              <input type="hidden" {...register('00N6D00000FaPdG')} />
              <input type="hidden" {...register('00N6D00000FaPdE')} />
            </>
          )}

          {process.env.NEXT_PUBLIC_ENV === 'UAT' && (
            <>
              <input type="hidden" {...register('00N9n000000QNTd')} />
              <input type="hidden" {...register('00N9n000000QNTg')} />
              <input type="hidden" {...register('00N9n000000QNTe')} />
            </>
          )}

          {process.env.NEXT_PUBLIC_ENV === 'PROD' && (
            <>
              <input type="hidden" {...register('00NMq0000000dgR')} />
              <input type="hidden" {...register('00NMq0000000dgU')} />
              <input type="hidden" {...register('00NMq0000000dgS')} />
            </>
          )}

          {/* NOTE: These fields are optional debugging elements. Please uncomment
    these lines if you wish to test in debug mode. */}
          {/* <input type="hidden" name="debug" value="1" />
          <input
            type="hidden"
            name="debugEmail"
            value="muhammad.binmuzaffar@8squad.com.au"
          /> */}
          <TextInput
            id="name"
            label="Full Name *"
            type="text"
            placeholder="Full Name"
            {...register('name')}
            readOnly={isSubmitting}
            hasError={!!errors?.name}
            fieldErrorMessage={errors?.name?.message}
          />
          <TextInput
            id="email"
            label="Email *"
            type="text"
            placeholder="Email"
            {...register('email')}
            readOnly={isSubmitting}
            hasError={!!errors?.['email']}
            fieldErrorMessage={errors?.['email']?.message}
          />
          <TextInput
            id="phone"
            label="Phone *"
            type="text"
            placeholder="Phone"
            {...register('phone')}
            readOnly={isSubmitting}
            hasError={!!errors?.phone}
            fieldErrorMessage={errors?.phone?.message}
          />
          <TextInput
            id="location"
            label="Your Location *"
            type="text"
            placeholder="Village/Postal Code"
            {...register('location')}
            readOnly={isSubmitting}
            hasError={!!errors?.location}
            fieldErrorMessage={errors?.location?.message}
          />
          <FieldSet label="State *" hasError={!!errors?.['state']}>
            {stateTerritoryValues.map((value, index) => (
              <RadioSelectionInput
                key={`case_${value}`}
                id={`case_${value}`}
                value={value}
                {...register('state')}
                readOnly={isSubmitting}
                // intentionally disable field to exclude from POST data
                disabled={isSubmitting}
                hasError={!!errors?.['state']}
                // render error after last checkbox radioSelection
                {...(index === stateTerritoryValues.length - 1 && {
                  fieldErrorMessage: errors?.['state']?.message
                })}
              >
                {value}
              </RadioSelectionInput>
            ))}
          </FieldSet>
          <FieldSet label="I want to *" hasError={!!errors?.['type']}>
            <RadioSelectionInput
              id="ask-a-question"
              value="Ask a Question"
              labelIcon={SVG_HELP_SOLID}
              {...register('type')}
              readOnly={isSubmitting}
              hasError={!!errors?.['type']}
            >
              Ask a question
            </RadioSelectionInput>
            <RadioSelectionInput
              id="raise-a-complaint"
              value="Raise a Complaint"
              labelIcon={SVG_FEEDBACK}
              {...register('type')}
              readOnly={isSubmitting}
              hasError={!!errors?.['type']}
            >
              Raise a complaint
            </RadioSelectionInput>
            <RadioSelectionInput
              id="provide-feedback"
              value="Raise a Compliment"
              labelIcon={SVG_QUESTION_ANSWER}
              {...register('type')}
              readOnly={isSubmitting}
              hasError={!!errors?.['type']}
              // render error after last checkbox checkBoxSelection
              fieldErrorMessage={errors?.['type']?.message}
            >
              Provide feedback
            </RadioSelectionInput>
          </FieldSet>
          <FieldSet>
            <TextAreaInput
              data-testid="detailsTextInput"
              id="description"
              label="Details *"
              placeholder="Please type some details of your feedback or enquiry"
              {...register('description')}
              readOnly={isSubmitting}
              hasError={!!errors?.['description']}
              fieldErrorMessage={errors?.['description']?.message}
            />
          </FieldSet>
          <FieldSet>
            {/* Terms Opt In */}
            <CheckboxInput
              id="optIn"
              {...register('optIn', {
                onChange: (event) =>
                  setValue('optIn', event.target.checked ? 1 : 0)
              })}
              readOnly={isSubmitting}
              // intentionally disable field to exclude from POST data
              disabled={isSubmitting}
              hasError={!!errors?.['optIn']}
              fieldErrorMessage={errors?.['optIn']?.message}
            >
              <span>
                {disclaimerRichText?.richText && (
                  <RichTextRender
                    bodyContent={disclaimerRichText?.richText}
                    pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                    renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                    forceLinksOpenNewWindow={true}
                  />
                )}
              </span>
            </CheckboxInput>
          </FieldSet>
          <FieldSet disabled={isSubmitting}>
            <div id="recaptcha-widget" ref={recaptchaRef} />
          </FieldSet>
          <FieldSet noBottomSpacing>
            <ButtonStandard
              data-testid="submitButton"
              type="submit"
              disabled={isSubmitting || !recaptchaVerified}
              color={COLOR_LIME}
              aria-label="Submit"
            >
              Submit
            </ButtonStandard>
          </FieldSet>
        </form>
      )}
    </>
  );
};

export default CreateCaseForm;
