import React, { FormEvent, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonStandard } from '@src/components/Button';
import HeadOfficePhoneNumber from '@src/components/HeadOfficePhoneNumber';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { CREATE_LEAD_FORM_SCHEMA } from '@src/constants/forms';
import {
  CheckboxInput,
  FieldSet,
  RadioSelectionInput,
  SelectInput,
  TextInput
} from '@src/elements/Form';
import { Column, Row } from '@src/elements/Grid';
import { GlobalSettingItemFragment, Maybe } from '@src/graphql/gql-types';
import { usePageFormSuccess } from '@src/hooks/usePageFormSuccess';
import { useRecaptcha } from '@src/hooks/useRecaptcha';
import { useWindow } from '@src/hooks/useWindow';
import { COLOR_LIME } from '@src/types/colors';
import {
  CreateLeadFormFields,
  FORM_ERROR_URL_PARAMETER,
  FORM_SUCCESS_URL_PARAMETER,
  NationalEnquiryDynamicFormValues,
  NationalEnquiryDynamicSelectBoxValue
} from '@src/types/forms';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import logger from '@src/utils/logger';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import UrlRedirectInput from '../UrlRedirectInput';
import styles from './CreateLeadForm.module.scss';
import { getSessionStorage } from '@src/services/sessionStorage';
import { UTM_PARAMS } from '@src/constants/utm';
import { useCommunityDetail } from '@src/hooks/useCommunityDetail';

type CreateLeadForm = {
  disclaimerRichText?: GlobalSettingItemFragment;
  formType:
    | 'national_enquiry'
    | 'community_enquiry'
    | 'community_contact'
    | 'community_event_rsvp';
  headOfficePhoneNumber?: Maybe<string>;
  nationalEnquiryDynamicFormValues?: NationalEnquiryDynamicFormValues;
  isModal?: boolean;
};

declare const grecaptcha: any;

type RedirectUrl =
  | FORM_ERROR_URL_PARAMETER.CONTACT
  | FORM_ERROR_URL_PARAMETER.ENQUIRY
  | FORM_SUCCESS_URL_PARAMETER.CONTACT
  | FORM_SUCCESS_URL_PARAMETER.ENQUIRY;

const CreateLeadForm: React.FC<CreateLeadForm> = ({
  disclaimerRichText,
  formType,
  headOfficePhoneNumber,
  nationalEnquiryDynamicFormValues = {},
  isModal = false
}) => {
  const windowObj = useWindow();
  const router = useRouter();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaVerified = useRecaptcha('recaptcha-widget-lead-form', isModal);
  const formSuccess = usePageFormSuccess();
  const [redirectUrl, setRedirectUrl] = useState<RedirectUrl>(
    formType.includes('contact')
      ? FORM_ERROR_URL_PARAMETER.CONTACT
      : FORM_ERROR_URL_PARAMETER.ENQUIRY
  );
  // stores dynamic selectbox values (villages for state/territory)
  const [communityPreferenceValues, setCommunityPreferenceValues] =
    useState<NationalEnquiryDynamicSelectBoxValue>([]);

  const isSingleFieldPerRowLayout = formType.includes('contact');

  // get UTM tracking data from sessionStorage to pass to form payload
  const { utm_source, utm_content, utm_medium, utm_campaign } =
    getSessionStorage(UTM_PARAMS) || {};

  const { communityDetail } = useCommunityDetail();

  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    reset: resetFormFields,
    formState: { errors }
  } = useForm<CreateLeadFormFields>({
    resolver: yupResolver(CREATE_LEAD_FORM_SCHEMA),
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      postCode: '',
      enquiringDetails: '', // enquiring for
      enquiryDate: '',
      bookVisit: '',
      optIn: '',
      channelOfEnquiry: '',
      websiteURL: '',
      leadSource: '',
      leadRecordType: '',
      sourceDetail: '',
      liveChatTranscript: '',
      utmCampaign: '',
      utmSource: '',
      utmMedium: '',
      utmContent: ''
    }
  });

  // dynamic state/territory radio values
  const stateTerritoryValues = Object.keys(nationalEnquiryDynamicFormValues);

  // update dynamic selectbox values (villages for state/territory)
  const handleStateTerritoryChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const fedState = target?.value;

    // manually clear state errors on change
    clearErrors('enquiringState');
    setCommunityPreferenceValues(nationalEnquiryDynamicFormValues[fedState]);
  };

  // submit form with synthetic delay after validating reCaptcha
  const formSubmit = () => {
    setIsSubmitting(true);
    try {
      if (!recaptchaVerified) {
        setIsSubmitting(false);
        return false;
      }

      // log submit to datadog
      logger.info('forms v2 submit Community Lead (web-to-lead)', {
        form: 'Community Lead (web-to-lead)',
        source: 'Web',
        service: 'Form'
      });
      const body = {
        ...getValues(),
        enquiryDate: new Intl.DateTimeFormat('en-AU').format(
          new Date(Date.now())
        ),
        optIn: getValues().optIn.toString() || 'false',
        bookVisit: getValues().bookVisit.toString() || 'false',
        channelOfEnquiry: 'Levande Website',
        leadSource: 'Website',
        leadRecordType: 'Website',
        websiteURL: windowObj.href,
        sourceDetail: 'levande.com.au',
        utmCampaign: utm_campaign,
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmContent: utm_content
      };

      fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/v1/leads/`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-api-key': process.env.NEXT_PUBLIC_LEVANDE_API_KEY || ''
        },
        body: JSON.stringify(body)
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setIsSubmitting(false);
            try {
              grecaptcha.enterprise.reset();
              // eslint-disable-next-line no-empty
            } catch {}
            resetFormFields();
            router.push(
              `${windowObj?.href?.split('#')[0]}#${
                formType.includes('contact')
                  ? FORM_SUCCESS_URL_PARAMETER.CONTACT
                  : FORM_SUCCESS_URL_PARAMETER.ENQUIRY
              }`
            );
            setRedirectUrl(
              formType.includes('contact')
                ? FORM_SUCCESS_URL_PARAMETER.CONTACT
                : FORM_SUCCESS_URL_PARAMETER.ENQUIRY
            );
          } else {
            setIsSubmitting(false);
            try {
              grecaptcha.enterprise.reset();
              // eslint-disable-next-line no-empty
            } catch {}
            router.push(
              `${windowObj?.href?.split('#')[0]}#${
                formType.includes('contact')
                  ? FORM_ERROR_URL_PARAMETER.CONTACT
                  : FORM_ERROR_URL_PARAMETER.ENQUIRY
              }`
            );
            setRedirectUrl(
              formType.includes('contact')
                ? FORM_ERROR_URL_PARAMETER.CONTACT
                : FORM_ERROR_URL_PARAMETER.ENQUIRY
            );
          }
        })
        .catch((error) => {
          console.log('Failed to submit form', error);
          setIsSubmitting(false);
          throw error;
        });
    } catch (error) {
      setIsSubmitting(false);
      // log exception to Sentry
      throw error;
    }
  };

  return (
    <>
      {formType.includes('contact') && formSuccess ? (
        <p data-testid="communitySuccessHeader">
          Thank you for submitting an enquiry, we will be in touch for a chat
          shortly.
        </p>
      ) : (
        <>
          {headOfficePhoneNumber && (
            <HeadOfficePhoneNumber
              preText="Please fill in the form below to enquire or call"
              headOfficePhoneNumber={headOfficePhoneNumber}
            />
          )}
          <form
            data-testid="communityLeadForm"
            noValidate
            onSubmit={handleSubmit(formSubmit)}
            ref={formRef}
            className={classNames(styles.communityLeadForm, {
              [styles.contactForm]: formType.includes('contact')
            })}
          >
            {/* retURL */}
            <UrlRedirectInput urlParam={redirectUrl} />

            <FieldSet noBottomSpacing>
              <Row>
                <Column
                  xs={4}
                  sm={isSingleFieldPerRowLayout ? 4 : 2}
                  md={isSingleFieldPerRowLayout ? 8 : 4}
                  lg={isSingleFieldPerRowLayout ? 8 : 4}
                  xl={isSingleFieldPerRowLayout ? 12 : 6}
                >
                  <SelectInput
                    data-testid="selectInputTitle"
                    id="salutation"
                    label="Title *"
                    {...register('title')}
                    readOnly={isSubmitting}
                    hasError={!!errors?.title}
                    fieldErrorMessage={errors?.title?.message}
                  >
                    <option value="">Select</option>
                    <option value="Mr.">Mr</option>
                    <option value="Mrs.">Mrs</option>
                    <option value="Ms.">Ms</option>
                  </SelectInput>
                </Column>
              </Row>

              <Row>
                <Column
                  xs={4}
                  sm={isSingleFieldPerRowLayout ? 4 : 2}
                  md={isSingleFieldPerRowLayout ? 8 : 4}
                  lg={isSingleFieldPerRowLayout ? 8 : 4}
                  xl={isSingleFieldPerRowLayout ? 12 : 6}
                >
                  <TextInput
                    id="first_name"
                    label="First Name *"
                    type="text"
                    placeholder="First name"
                    {...register('firstName')}
                    readOnly={isSubmitting}
                    hasError={!!errors?.firstName}
                    fieldErrorMessage={errors?.firstName?.message}
                  />
                </Column>
                <Column
                  xs={4}
                  sm={isSingleFieldPerRowLayout ? 4 : 2}
                  md={isSingleFieldPerRowLayout ? 8 : 4}
                  lg={isSingleFieldPerRowLayout ? 8 : 4}
                  xl={isSingleFieldPerRowLayout ? 12 : 6}
                >
                  <TextInput
                    id="last_name"
                    label="Last Name *"
                    type="text"
                    placeholder="Last name"
                    {...register('lastName')}
                    readOnly={isSubmitting}
                    hasError={!!errors?.lastName}
                    fieldErrorMessage={errors?.lastName?.message}
                  />
                </Column>
              </Row>

              <Row>
                <Column
                  xs={4}
                  sm={isSingleFieldPerRowLayout ? 4 : 2}
                  md={isSingleFieldPerRowLayout ? 8 : 4}
                  lg={isSingleFieldPerRowLayout ? 8 : 4}
                  xl={isSingleFieldPerRowLayout ? 12 : 6}
                >
                  <TextInput
                    id="email"
                    label="Email *"
                    type="text"
                    placeholder="Email"
                    {...register('emailAddress')}
                    readOnly={isSubmitting}
                    hasError={!!errors?.emailAddress}
                    fieldErrorMessage={errors?.emailAddress?.message}
                  />
                </Column>
                <Column
                  xs={4}
                  sm={isSingleFieldPerRowLayout ? 4 : 2}
                  md={isSingleFieldPerRowLayout ? 8 : 4}
                  lg={isSingleFieldPerRowLayout ? 8 : 4}
                  xl={isSingleFieldPerRowLayout ? 12 : 6}
                >
                  <TextInput
                    id="phone"
                    label="Phone *"
                    type="text"
                    placeholder="Phone"
                    {...register('phoneNumber')}
                    readOnly={isSubmitting}
                    hasError={!!errors?.phoneNumber}
                    fieldErrorMessage={errors?.phoneNumber?.message}
                  />
                </Column>
              </Row>

              <TextInput
                id="zip"
                label="Postcode *"
                type="text"
                placeholder="Postcode"
                {...register('postCode')}
                readOnly={isSubmitting}
                hasError={!!errors?.postCode}
                fieldErrorMessage={errors?.postCode?.message}
              />
            </FieldSet>

            {/* hidden fields in community form with state and village data */}
            {formType.includes('community') && (
              <>
                <input
                  type="hidden"
                  value={communityDetail?.state}
                  {...register('enquiringState')}
                />
                <input
                  type="hidden"
                  value={communityDetail?.title}
                  {...register('villagePreference')}
                />
              </>
            )}

            {/* dynamic state/territory radio values */}
            {formType === 'national_enquiry' && (
              <FieldSet
                label="Interested in"
                hasError={!!errors?.['enquiringState']}
              >
                {stateTerritoryValues.map((value, index) => (
                  <RadioSelectionInput
                    key={value}
                    id={value}
                    value={value}
                    {...register('enquiringState', {
                      onChange: (e) => handleStateTerritoryChange(e)
                    })}
                    readOnly={isSubmitting}
                    hasError={!!errors?.['enquiringState']}
                    // render error after last checkbox radioSelection
                    {...(index === stateTerritoryValues.length - 1 && {
                      fieldErrorMessage: errors?.['enquiringState']?.message
                    })}
                  >
                    {value}
                  </RadioSelectionInput>
                ))}
              </FieldSet>
            )}

            {/* dynamic selectbox values (villages for state/territory) */}
            {formType === 'national_enquiry' && (
              <Row>
                <Column xs={4} sm={2} md={4} lg={4} xl={6}>
                  <SelectInput
                    data-testid="communityPreference"
                    id="community_preference"
                    label="Community Preference"
                    {...register('villagePreference')}
                    disabled={!communityPreferenceValues.length}
                    readOnly={isSubmitting}
                    hasError={!!errors?.['villagePreference']}
                    fieldErrorMessage={errors?.['villagePreference']?.message}
                  >
                    <option value="">Select</option>
                    {communityPreferenceValues.map((community) => (
                      <option key={community.sys.id} value={community.title}>
                        {community.title}
                      </option>
                    ))}
                  </SelectInput>
                </Column>
              </Row>
            )}

            <FieldSet label="Enquiring for">
              <RadioSelectionInput
                id="myself"
                value="Myself"
                {...register('enquiringDetails')}
                readOnly={isSubmitting}
                hasError={!!errors?.enquiringDetails}
              >
                Myself
              </RadioSelectionInput>
              <RadioSelectionInput
                id="family"
                value="For Family"
                {...register('enquiringDetails')}
                readOnly={isSubmitting}
                hasError={!!errors?.enquiringDetails}
              >
                For Family
              </RadioSelectionInput>
              <RadioSelectionInput
                id="friends"
                value="For Friends"
                {...register('enquiringDetails')}
                readOnly={isSubmitting}
                hasError={!!errors?.enquiringDetails}
                // render error after last checkbox checkBoxSelection
                fieldErrorMessage={errors?.enquiringDetails?.message}
              >
                For Friends
              </RadioSelectionInput>
            </FieldSet>
            {formType.includes('community') && (
              <FieldSet>
                <CheckboxInput
                  id="communityContactBookVisit"
                  {...register('bookVisit')}
                  readOnly={isSubmitting}
                  // intentionally disable field to exclude from POST data
                  disabled={isSubmitting}
                  hasError={!!errors?.bookVisit}
                  fieldErrorMessage={errors?.bookVisit?.message}
                >
                  Book a visit
                </CheckboxInput>
              </FieldSet>
            )}
            <FieldSet>
              <CheckboxInput
                data-testid="communityContactTerms"
                id="communityContactTerms"
                {...register('optIn')}
                readOnly={isSubmitting}
                // intentionally disable field to exclude from POST data
                disabled={isSubmitting}
                hasError={!!errors?.optIn}
                fieldErrorMessage={errors?.optIn?.message}
              >
                <span>
                  {disclaimerRichText?.richText && (
                    <RichTextRender
                      bodyContent={disclaimerRichText?.richText}
                      pageType={
                        formType === 'community_enquiry'
                          ? CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_STANDARD
                          : CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD
                      } // Forcing this type at the moment, but it might not be needed/or applied in this instance
                      renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                      forceLinksOpenNewWindow={true}
                    />
                  )}
                </span>
              </CheckboxInput>
            </FieldSet>
            <FieldSet disabled={isSubmitting}>
              <div id="recaptcha-widget-lead-form" />
            </FieldSet>
            <FieldSet noBottomSpacing>
              <ButtonStandard
                data-testid="submitButton"
                type="submit"
                disabled={isSubmitting || !recaptchaVerified}
                color={COLOR_LIME}
                aria-label="Submit"
              >
                Submit
              </ButtonStandard>
            </FieldSet>
          </form>
        </>
      )}
    </>
  );
};

export default CreateLeadForm;
